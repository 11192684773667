import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";


@Injectable({ providedIn: 'root' })
export class GeneralService {
    isBrowser!: boolean;
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        this.isBrowser = isPlatformBrowser(this.platformId);
    }

    webViewPostMessage(token: string) {
        if (this.isBrowser) {
            if ((window as any).webkit && (window as any).webkit.messageHandlers && (window as any).webkit.messageHandlers.iosListener) {
                (window as any).webkit.messageHandlers.iosListener.postMessage(token);
            } else {
                console.error('iOS mesaj işleyicisi mevcut değil');
            }
            if ((window as any).Android && (window as any).Android.showToast) {
                (window as any).Android.showToast(token);
            }
        }
    }
}