import { Injectable } from "@angular/core";
import { DateFormatEnum } from "../enums/date-format.enum";

@Injectable({providedIn: 'root'})
export class DateFormatService {
    constructor() {}

    formatDate(stringDate:string ,dateFormat: string): string {
        let date = new Date(stringDate)
        let day = date.getDate();
        let month = date.getMonth() + 1
        let year = date.getFullYear();
        if(DateFormatEnum.DD_MM_YYYY == dateFormat) {
            return day + '/' + month + '/' + year
        }
        return ''
    }
}